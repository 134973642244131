export default {
  address: "台北市大安區大安路一段206巷15號",
  googleSrc:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.977362821159!2d121.54269841500629!3d25.03484228397193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x76b0fadb2644faaf!2z5aSn5a6J6KyZ6Z-1IOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1628075189539!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/xmUat3NvLhvi8A7k7",
  phone: "02-23256688",
  fbLink: "https://www.facebook.com/大安謙韵-102705448688872",
  fbMessage: "https://m.me/102705448688872",
  caseName: "大安謙韵",
  indigatorLength: 10,

  houseInfos: [
    ["投資興建", "興富謙建設股份有限公司"],
    ["建築規劃", "如榆設計BASS Design"],
    ["風格美學", "元佑設計YUANYOU Design"],
    ["結構工程", "築遠工程顧問有限公司"],
    ["營建工程", "地樺營造"],
    ["綠建築與智慧建築顧問", "楊謙柔"],
    ["建築代銷", "聯碩行銷股份有限公司"],
    ["建照號碼", "北市110建字第0089號"],
    ["不動產經紀人", "徐福龍（91）北市經證字第00848號"]
  ],

  gtmCode: ["MRL4BX2"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
    title: "預約賞屋",
    subTitle: ""
  }
};